











































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic,
} from "@/configuration";
import { EventBus } from "@/main";

@Component({
  metaInfo: {
    title: "RedCapital  | Plataforma de Financiamiento Digital ",
    //titleTemplate: TitleTemplatePublic,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  data() {
	return {
		navigationNext: `<i class="material-icons primary--text">arrow_forward_ios</i>`,
		navigationPrevious: `<i class="material-icons primary--text">arrow_back_ios</i>`,
		pressData: [{
			alt: "Pulso",
			image: "/images/pulso-logo.png"
		},{
			alt: "El Mercurio",
			image: "/images/mercurio-logo.png"
		},{
			alt: "EMOL",
			image: "/images/emol-logo.png"
		},{
			alt: "CNN Chile",
			image: "/images/cnn-logo.png"
		},{
			alt: "Diario Financiero",
			image: "/images/df-logo.png"
		},{
			alt: "Forbes",
			image: "/images/forbes-logo.png"
		},{
			alt: "Las Últimas Noticias",
			image: "/images/lun-logo.png"
		},{
			alt: "Capital",
			image: "/images/capital-logo.png"
		},{
			alt: "NewNexus",
			image: "/images/nexnews-logo.png"
		}],
		options: {
			press: {
				perPage: 4
			}
		},
	}
  },
  mounted: 
  	function(){
    if (this.$route.hash) {
      //@ts-ignore
      setTimeout(() => this.scrollTo(this.$route.hash), 1)
    }
    // @ts-ignore
    this.onResponsive();
    // @ts-ignore
    window.addEventListener("resize", this.onResponsive);
  },
  methods: {
	gohablemos(){
	  EventBus.$emit("goHablemos");
	},
    scrollTo: function (hashtag) {
      setTimeout(() => { location.href = hashtag }, 1)
    },
    onResponsive() {
      let w = window.innerWidth;
      if (w < 600) {
        this.$data.options.press.perPage = 2;
      } else if (w > 600 && w < 960) {
        this.$data.options.press.perPage = 3;
      } else if (w > 960 && w < 1264) {
        this.$data.options.press.perPage = 4;
      } else if (w > 1264 && w < 1904) {
        this.$data.options.press.perPage = 5;
      } else if (w > 1904) {
        this.$data.options.press.perPage = 8;
      }
    },
  },
  beforeRouteUpdate (to, from, next) {
    if(to.hash){
      //@ts-ignore
      setTimeout(() => this.scrollTo(this.$route.hash), 1)
    }
    next();
  }, 
})
export default class About extends Vue {}
